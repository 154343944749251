.App {
  text-align: center;
  background: linear-gradient(138deg, rgb(26, 30, 38) 0%, rgb(61, 79, 115) 73%, rgb(61, 64, 115) 100%);
    min-height: 100vh;
}

.App-logo {
  height: 10vmin; /* Adjust size as needed */
  pointer-events: none;
  margin-bottom: 2rem; /* Add space below the logo */
}

.App-header {
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
}

.App-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 100px); /* Adjust header height accordingly */
}

@media (max-width: 600px) {
  .App-logo {
    height: 15vmin; /* Adjust size for smaller screens */
  }
}



/* src/App.css */

.logout-button {
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 8px 16px;
  background-color: #f44336;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
}

.logout-button:hover {
  background-color: #d32f2f;
}

