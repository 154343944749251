/* src/Login.css */

/* .login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
  }
  
  .login-container input {
    margin: 10px 0;
    padding: 8px;
    width: 200px;
  }
  
  .login-container .error {
    color: red;
    margin-top: 10px;
  }
  
  .login-container button {
    padding: 8px 16px;
    cursor: pointer;
  } */
  

  /* src/Login.css */

.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    
    min-height: 100vh;
    background: linear-gradient(138deg, rgb(26, 30, 38) 0%, rgb(61, 79, 115) 73%, rgb(61, 64, 115) 100%);

    
  }
  
  .login-header {
    margin-top: 100px;
    display: flex;
    align-items: center;
    color: white;
  }
  
  .login-logo {
    height: 40px; /* Adjust the height as needed */
    margin-right: 10px;
  }
  
  .login-container input {
    margin: 10px 0;
    /* padding: 8px; */
    width: 200px;
    color: #666;
    padding: .75em;
    height: auto;
    border-width: 1px;
    border-style: solid;
    border-color: #F2F5F7;
    border-radius: 14px;
    background: #F9FAFB;
    box-shadow: none;
    box-sizing: border-box;
  }
  .login-container input:focus {
    outline: none;
  }
  
  .login-container .error {
    color: red;
    margin-top: 10px;
  }
  
  .login-container button {
    margin-top: 5px;
    border-style: solid;
    border-top-width: 0;
    border-right-width: 0;
    border-left-width: 0;
    border-bottom-width: 0;
    color: #1a1e26;
    border-color: #5abfa3;
    background-color: #5abfa3;
    padding-top: 15px;
    padding-right: 25px;
    padding-bottom: 15px;
    padding-left: 25px;
    font-family: inherit;
    font-weight: 600;
    font-size: 20px;
    font-size: 1.1111111111111rem;
    line-height: 1em;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    border-bottom-left-radius: 30px;
    cursor: pointer;
  }
  